import React from 'react'
import {
  AlternativeHero,
  Breadcrumbs,
  Page,
  Impressum,
} from '@components'

const meta = {
  title: 'Impressum | Protupožarna zaštita - FSB d.o.o.',
  description: 'FSB d.o.o. obvezuje se na zaštitu vaših osobnih podatka i zadržava ih samo onoliko dugo koliko su potrebni.',
}

const ImpressumPage = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/impressum/', name: 'Impressum'},
      ]}
    />
    <AlternativeHero
      title="Impressum"
      subtitle=""
      description=""
    />
    <Impressum />
  </Page>
)

export default ImpressumPage